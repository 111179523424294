import { Configure, useHits } from "react-instantsearch";

import { useLocale } from "next-intl";
import { twMerge } from "tailwind-merge";

import SearchPagination from "@efarmz/efarmz-react-commons/esm/search/SearchPagination";

import ProductCard from "@/components/molecules/ProductCard";

import SearchProvider from "@/components/search/SearchProvider";
import { Link } from "@/utils/navigation";

const Hits = (props: any) => {
  const locale = useLocale();
  const { hitProps, legacy, ...other } = props;
  const { hits }: any = useHits(other);

  const { className: hitClassName, style: hitStyle } = hitProps;

  const Items = hits.map((hit: any) => {
    return (
      <Link
        key={hit.objectID}
        href={`/shop/${hit.id}+${hit.slug}` as any}
        target={legacy ? `_parent` : undefined}
      >
        <ProductCard
          product={hit}
          className={twMerge(`h-full`, hitClassName)}
          style={hitStyle as any}
        />
      </Link>
    );
  });

  return <div className="grid grid-cols-2 sm:grid-cols-5 gap-5">{Items}</div>;
};

type ProductListGridElasticProps = {
  settings: any;
  filters: any;
  className?: string;
  hitProps?: {
    className?: string;
    style?: object;
  };
  legacy?: boolean;
};

const ProductListGridElastic = ({
  className,
  settings,
  filters,
  hitProps = {},
  legacy = false,
}: ProductListGridElasticProps) => {
  const locale = useLocale();

  return (
    <div className={className}>
      <SearchProvider index={`orderable-items-${locale}`} settings={settings}>
        <Configure filters={filters} />

        <Hits hitProps={hitProps} legacy={legacy} />
        <div className="mt-4">
          <SearchPagination />
        </div>
      </SearchProvider>
    </div>
  );
};

export default ProductListGridElastic;
