"use client";

import React, { useEffect, useRef, useState } from "react";

import { useHover } from "usehooks-ts";

import { Product } from "@efarmz/efarmz-domain-typescript";

import useSegment from "@/hooks/useSegment";

import formatOrderableItem from "@/utils/shared/segment/formatOrderableItem";

export type ProductCardTrackProps = {
  product: Product;
  className?: string;
  children: React.ReactNode;
};

const ProductCardTrack = ({
  product,
  className,
  children,
}: ProductCardTrackProps) => {
  const [hasBeenTracked, setHasBeenTracked] = useState(false);
  const { track } = useSegment();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  useEffect(() => {
    if (!hasBeenTracked && isHover) {
      track(`OrderableItemViewed`, {
        products: [formatOrderableItem(product)],
        currency: `EUR`,
        value: product.finalPrice,
      });

      setHasBeenTracked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHover]);

  return (
    <div ref={hoverRef} className={className}>
      {children}
    </div>
  );
};

export default ProductCardTrack;
