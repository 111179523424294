import { createClient } from "@sanity/client";

export const projectId = `o3k1g4l4`;
export const dataset = `production`!;

const Sanity = createClient({
  projectId,
  dataset,
  token: `skVrzIuNLcUbT4riU07W8GnM6sICAro3GwYcr0RUEYyYrg6qLb9fXoPFKiBIkQgIQAfJBfUeh3e3lSFfSBFhRm3ezN9tSlwY6cPgC6ViWttcMFdCsQBapI3DuZM3nR8zRJsJSNowNtHSVhnAFJgFn0EI1sfG2TpN6VHUKN51CeZaCPErmCT3`, // we need this to get write access
  useCdn: false, // We can't use the CDN for writing
  apiVersion: `2023-05-03`,
});

export default Sanity;
