import { useCallback } from "react";

import {
  Category,
  OrderableItem,
  OrderableItemTag,
} from "@efarmz/efarmz-domain-typescript";

import urlFromCategory from "@/components/search/utils/urlFromCategory";

type useSearchHelperOutput = {
  getUrlFromCategory: (categories: Category[]) => string;
  getShopItemUrl: (item: OrderableItem) => string;
  getTagUrl: (tag: OrderableItemTag) => string;
};

//https://react.bmarguerite.fr/fr/shop?orderable-items-fr%5BhierarchicalMenu%5D%5Bcategories_level_0%5D%5B0%5D=Cr%C3%A8merie&orderable-items-fr%5BhierarchicalMenu%5D%5Bcategories_level_0%5D%5B1%5D=Yaourts&orderable-items-fr%5BhierarchicalMenu%5D%5Bcategories_level_0%5D%5B2%5D=Brebis
const useSearchHelper = (locale: string): useSearchHelperOutput => {
  const getUrlFromCategory = (categories: Category[]) =>
    urlFromCategory(locale, categories);

  const getShopItemUrl = useCallback((item: OrderableItem) => {
    if (item.id && item.slug) {
      return `/shop/${item.id}+${encodeURIComponent(item.slug)
        .replace(/'/g, `&#39;`)
        .replace(/[!'()*]/g, function (c) {
          return `%${c.charCodeAt(0).toString(16)}`;
        })
        .replace(`.`, ``)}`;
    }
    return `/shop`;
  }, []);

  const getTagUrl = useCallback((tag: OrderableItemTag) => {
    return `/shop?${encodeURIComponent(
      `orderable-items-fr[menu][tags]`
    )}=${tag}`;
  }, []);

  return {
    getShopItemUrl,
    getUrlFromCategory,
    getTagUrl,
  };
};

export default useSearchHelper;
