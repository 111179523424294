import { Configure, useHits, useInstantSearch } from "react-instantsearch";

import { useLocale } from "next-intl";
import { twMerge } from "tailwind-merge";

import { Product } from "@efarmz/efarmz-domain-typescript";

import Gallery from "@/components/atoms/Gallery";

import ProductCard from "@/components/molecules/ProductCard";

import useSearchHelper from "@/hooks/helpers/useSearchHelper";

import ProductListSliderSkeleton from "../ProductListSlider/ProductListSliderSkeleton";

import { ProductCardLegacyProps } from "@/components/ProductCardLegacy";
import SearchProvider from "@/components/search/SearchProvider";
import { Link } from "@/utils/navigation";

const Hits = (props: any) => {
  const { hitProps, customSort, ...other } = props;
  const locale = useLocale();
  const searchHelper = useSearchHelper(locale);
  const { hits }: any = useHits(other);
  const { status } = useInstantSearch();

  const { className: hitClassName, ...hitOtherProps } = hitProps || {};

  const sortHits = customSort(hits);

  const Items = sortHits.map((hit: any) => {
    return (
      <Link
        key={hit.objectID}
        className="!h-auto min-w-72 snap-end"
        // @ts-ignore
        href={searchHelper.getShopItemUrl(hit as any)}
      >
        <ProductCard
          product={hit}
          className={twMerge(`h-full`, hitClassName)}
          {...hitOtherProps}
        />
      </Link>
    );
  });
  if (status === `idle`) {
    return (
      <>
        <Gallery className="min-h-[430px]">{Items}</Gallery>
      </>
    );
  }
  return <ProductListSliderSkeleton />;
};

type ProductListSliderElasticProps = {
  settings: any;
  filters: any;
  numericFilters?: any;
  facetFilters?: any;
  className?: string;
  hitProps?: Omit<ProductCardLegacyProps, `product`>;
  legacy?: boolean;
  customSort?: (products: Product[]) => Product[];
};

const ProductListSliderElastic = ({
  filters,
  numericFilters,
  facetFilters,
  hitProps = {},
  legacy = false,
  customSort = (products) => products,
}: Omit<ProductListSliderElasticProps, `settings`>) => {
  return (
    <>
      <Configure
        filters={filters}
        numericFilters={numericFilters}
        facetFilters={facetFilters}
      />

      <Hits hitProps={hitProps} legacy={legacy} customSort={customSort} />
    </>
  );
};

const ProductListSliderElasticWrapper = ({
  settings,
  ...props
}: ProductListSliderElasticProps) => {
  const locale = useLocale();
  return (
    <SearchProvider index={`orderable-items-${locale}`} settings={settings}>
      <ProductListSliderElastic {...props} />
    </SearchProvider>
  );
};

export default ProductListSliderElasticWrapper;
