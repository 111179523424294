"use client";

import React, { useRef, useState } from "react";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";

import { twMerge } from "tailwind-merge";

import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

type GalleryProps = {
  className?: string;
  children: React.ReactNode;
  Action?: React.ReactNode;
};

const Gallery = ({ className, children, Action }: GalleryProps) => {
  const ref: any = useRef(null);

  const [{ isStartPosition, isEndPosition }, setState] = useState({
    isStartPosition: true,
    isEndPosition: false,
  });

  const handleScroll = (offset: number) => () => {
    if (ref?.current) {
      const start = ref?.current?.scrollLeft === 0;
      const end =
        ref?.current?.scrollWidth - ref?.current?.scrollLeft ===
        ref?.current?.clientWidth;

      if (end) {
        setState({
          isStartPosition: false,
          isEndPosition: true,
        });
      }
      if (start) {
        setState({
          isStartPosition: true,
          isEndPosition: false,
        });
      }

      if (!start && !end && (isStartPosition || isEndPosition)) {
        setState({
          isStartPosition: false,
          isEndPosition: false,
        });
      }

      (ref.current as any).scrollBy({
        left: offset * (true ? 1 : -1),
        behavior: `smooth`,
      });
    }
  };

  return (
    <>
      <div
        className={twMerge(
          `flex overflow-x-scroll gap-6 py-4 snap-mandatory snap-x scrollbar-thin scrollbar-thumb-vert-500 scrollbar-track-menthe-100`,
          className
        )}
        ref={ref}
      >
        {children}
      </div>
      <div className="flex justify-end items-center mt-2 gap-2">
        <div className="flex-1">{Action}</div>
        <Button
          onClick={handleScroll(-292)}
          disabled={isStartPosition}
          className="!p-2"
        >
          <HiArrowLongLeft className="w-7 h-7" />
        </Button>
        <Button
          onClick={handleScroll(292)}
          disabled={isEndPosition}
          className="!p-2"
        >
          <HiArrowLongRight className="w-7 h-7" />
        </Button>
      </div>
    </>
  );
};
export default Gallery;
