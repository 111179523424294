import ProductListSliderSkeleton from "@/components/organisms/ProductListSlider/ProductListSliderSkeleton";

const SanityProductTagSectionSkeleton = () => {
  const Tags = Array.from(Array(4).keys()).map((key) => {
    return (
      <div
        key={key}
        className={`flex bg-white rounded-md overflow-hidden items-center p-0 hover:bg-menthe-100`}
      >
        <div
          className={`w-10 h-10  text-white flex items-center justify-center bg-gray-200`}
        />

        <div className="px-5 text-slate-700 font-medium grow">
          <span
            className={`h-3 w-24 block bg-gray-200 rounded-md animate-pulse`}
          />
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="grid gap-2 sm:gap-0 grid-cols-2 sm:flex pb-6 sm:space-x-6">
        {Tags}
      </div>
      <ProductListSliderSkeleton />
    </>
  );
};

export default SanityProductTagSectionSkeleton;
