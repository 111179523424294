import { ProductActionButtonsSkeleton } from "../ProductActionButtons";

export type ProductCardSkeletonProps = {
  showButton?: boolean;
};
const ProductCardSkeleton = ({
  showButton = true,
}: ProductCardSkeletonProps) => {
  return (
    <div
      className={`relative group flex flex-col w-full overflow-hidden rounded-lg bg-white focus-within:ring-2 focus-within:ring-menthe-300 focus-within:ring-offset-2 focus-within:ring-offset-gray-100`}
    >
      <div className={`relative aspect-w-6 aspect-h-4 bg-gray-200`}></div>

      <div className="p-3 grow flex flex-col justify-between">
        <div className="grow">
          <div className="flex justify-between">
            <div>
              <h4 className="text-md font-semibold text-black">
                <span className="block h-3.5 bg-gray-200 rounded-full  w-48" />
              </h4>
              <p className="text-sm text-menthe-500 font-medium mt-2">
                <span className="block h-2.5 bg-gray-200 rounded-full  w-40" />
              </p>
              <div className="py-3 flex items-center gap-2">
                <div className="font-medium flex-1 flex items-center h-full whitespace-nowrap truncate">
                  <span className="block h-2.5 bg-gray-200 rounded-full  w-24" />
                </div>
                <div className="font-medium flex-1 flex items-center h-full whitespace-nowrap truncate">
                  <span className="block h-2.5 bg-gray-200 rounded-full  w-24" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`flex justify-between mt-2 items-center`}>
          <div className="flex items-center">
            <span className="bg-menthe-100 p-2 rounded-md text-sm text-vert-500 font-semibold">
              <span className="block h-2.5 bg-gray-200 rounded-full  w-24" />
            </span>

            <span className="ml-2 text-sm text-gray-700">
              <span className="block h-2.5 bg-gray-200 rounded-full w-24" />
            </span>
          </div>
          <div className="flex space-x-2 items-center"></div>
        </div>
        {showButton && <ProductActionButtonsSkeleton />}
      </div>
    </div>
  );
};

export default ProductCardSkeleton;
