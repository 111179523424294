import { useMemo } from "react";

import { addDays, format, getDay } from "date-fns";
import { useLocale } from "next-intl";
import { useRecoilState } from "recoil";

import { Brand, Product } from "@efarmz/efarmz-domain-typescript";

import { search_attributes } from "@/components/molecules/ProductCard";

import ProductListGridElastic from "@/components/organisms/ProductListGridElastic";
import ProductListSliderElastic from "@/components/organisms/ProductListSliderElastic";

import { ProductCardLegacyProps } from "@/components/ProductCardLegacy";
import { DeliveryDateLegacyAtom } from "@/state/atoms/DeliveryDateLegacyAtom";

type Section = {
  variant: `GRID` | `SLIDER`;
  source: `DEFAULT` | `CUSTOM`;
  color?: {
    hex?: string;
  };
  categories: any[];
  products?: Product[];
  brands?: Brand[];
  sort?: string;
};

type SanityProductCategorySliderSectionProps = {
  section: Section;
  className?: string;
  hitProps?: {
    className?: string;
    style?: object;
  };
  legacy?: boolean;
};

const SanityProductCategorySliderSection = ({
  section,
  className,
  legacy = false,
}: SanityProductCategorySliderSectionProps) => {
  const locale = useLocale();

  const [deliveryDate] = useRecoilState(DeliveryDateLegacyAtom);

  const nextDefaultDelivery = addDays(new Date(), 2);

  const defaultDeliveryDate = format(
    getDay(nextDefaultDelivery) === 0
      ? addDays(nextDefaultDelivery, 1)
      : nextDefaultDelivery,
    `yyyyMMdd`
  );

  const sortOption = useMemo(() => {
    let sort = `stats.sell-qty-${locale}`;
    if (section?.sort === `CREATED_AT`) {
      sort = `createdAt`;
    }
    if (section?.sort === `UPDATED_AT`) {
      sort = `updatedAt`;
    }
    return sort;
  }, [section?.sort, locale]);

  const Slider = useMemo(() => {
    const hitProps: Omit<ProductCardLegacyProps, `product`> = section?.color
      ?.hex
      ? {
          imgWrapperProps: {
            className: `border-b-4`,
            style: {
              borderColor: section?.color?.hex,
            },
          },
        }
      : { className: undefined, style: undefined };

    const settings = {
      sorting: {
        default: {
          field: sortOption,
          order: `desc`,
        },
      },
      search_attributes: [
        `categories_level_0^3`,
        `categories_level_1^3`,
        `categories_level_2^3`,
      ],

      result_attributes: search_attributes,
      filter_attributes: [
        { attribute: `id`, field: `id.keyword`, type: `string` },
        { attribute: `type`, field: `type.keyword`, type: `string` },
        { attribute: `isOnline`, field: `isOnline`, type: `string` },
        {
          attribute: `stock`,
          field: `stock.${deliveryDate || defaultDeliveryDate}`,
          type: `numeric`,
        },
        {
          attribute: `categories_level_0`,
          field: `categories_level_0.keyword`,
          type: `string`,
        },

        {
          attribute: `categories_level_1`,
          field: `categories_level_1.keyword`,
          type: `string`,
        },
        {
          attribute: `categories_level_1`,
          field: `categories_level_1.keyword`,
          type: `string`,
        },
        {
          attribute: `categories_level_2`,
          field: `categories_level_2.keyword`,
          type: `string`,
        },
        {
          attribute: `categories`,
          field: `categories.id.keyword`,
          type: `string`,
        },
        {
          attribute: `brand`,
          field: `brand.id.keyword`,
          type: `string`,
        },
      ],
      /*
search_attributes: [`name`, `email`],
result_attributes: [`id`, `name`, `email`],
highlight_attributes: [`name`],
*/
    };

    if (section?.source === `CUSTOM` && section?.products?.length) {
      const productsIds = section?.products.map(
        (product) => `id:"${product.id}"`
      );

      const filters = `isOnline:true AND (${productsIds.join(` OR `)})`;

      const order: Record<string, number> = {};

      section?.products.forEach((product, i) => {
        if (product.id) {
          order[product.id] = i;
        }
      });

      const customSort = (products: any[]) => {
        return products.sort((a, b) => {
          if (order[a.id] < order[b.id]) {
            return -1;
          }
          if (order[a.id] > order[b.id]) {
            return 1;
          }
          return 0;
        });
      };

      return section?.variant === `GRID` ? (
        <ProductListGridElastic
          settings={settings}
          filters={filters}
          hitProps={hitProps}
          className={className}
          legacy={legacy}
        />
      ) : (
        <ProductListSliderElastic
          settings={settings}
          filters={filters}
          hitProps={hitProps}
          className={className}
          legacy={legacy}
          numericFilters={[`stock>0`]}
          customSort={customSort}
        />
      );
    }

    const categoryFilters = section?.categories?.length
      ? section.categories.map((category) => `categories:"${category.id}"`)
      : [];

    const brandFilters = section?.brands?.length
      ? section.brands.map((brand) => `brand:"${brand.id}"`)
      : [];

    const filters = `isOnline:true AND type:PRODUCT ${
      categoryFilters.length > 0 ? `AND (${categoryFilters.join(` OR `)})` : ``
    } ${brandFilters.length > 0 ? `AND (${brandFilters.join(` OR `)})` : ``}`;

    return section?.variant === `GRID` ? (
      <ProductListGridElastic
        settings={settings}
        filters={filters}
        hitProps={hitProps}
        className={className}
        legacy={legacy}
      />
    ) : (
      <ProductListSliderElastic
        settings={settings}
        filters={filters}
        hitProps={hitProps}
        className={className}
        legacy={legacy}
        numericFilters={[`stock>0`]}
      />
    );
  }, [
    section?.color?.hex,
    section?.source,
    section?.products,
    section.categories,
    section.brands,
    section?.variant,
    sortOption,
    deliveryDate,
    defaultDeliveryDate,
    className,
    legacy,
  ]);

  return (
    <div className={className}>
      <div>{Slider}</div>
    </div>
  );
};

export default SanityProductCategorySliderSection;
