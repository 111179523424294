"use client";

import React, { useState } from "react";

import NextImage, { ImageProps as NextImageProps } from "next/image";
import { twMerge } from "tailwind-merge";

export type FlagProps = Omit<NextImageProps, `loader` | `src` | `alt`> & {
  code: string;
  effect?: `fade` | `blur`;
};

const Flag = (props: FlagProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { effect = `fade`, className, code, ...other } = props;

  const handleLoadingComplete = () => {
    setIsLoaded(true);
  };
  const src = `https://cdn.efarmz.be/flags/svgs/${code.toLocaleLowerCase()}.svg`;
  return (
    <NextImage
      src={src}
      alt={code}
      width={0}
      height={0}
      {...other}
      onLoad={handleLoadingComplete}
      className={twMerge(
        `transition-all`,
        effect === `fade` && `opacity-0 duration-200 ease-in`,
        effect === `fade` && isLoaded && `opacity-100`,
        effect === `blur` && `grayscale blur-2xl`,
        effect === `blur` && isLoaded && `grayscale-0 blur-0`,
        className
      )}
      onError={() => {
        setHasError(true);
      }}
      loader={
        hasError
          ? undefined
          : ({ src, width: w, quality }) => {
              const q = quality || 75;
              return `https://cdn.efarmz.be/cdn-cgi/image/quality=${q},f=auto,width=${w}/${src}`;
            }
      }
    />
  );
};
export default Flag;
