"use client";

import { useState } from "react";

import { createClient } from "@sanity/client";
import NextImage, { ImageProps as NextImageProps } from "next/image";
import { useNextSanityImage } from "next-sanity-image";
import { twMerge } from "tailwind-merge";

import { dataset, projectId } from "../../../utils/sanity";

type ImageProps = Omit<NextImageProps, `loader` | `src`> & {
  image: any;
  effect?: `fade` | `blur`;
};

const sanity = createClient({
  projectId,
  dataset,
  useCdn: true, // We can't use the CDN for writing
  apiVersion: `2023-05-03`,
});

const Image = ({
  image,
  className,
  effect = `fade`,
  ...other
}: ImageProps): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false);

  const imageProps: any = useNextSanityImage(sanity as any, image);

  const handleLoadingComplete = () => {
    setIsLoaded(true);
  };

  return (
    <NextImage
      onLoad={handleLoadingComplete}
      className={twMerge(
        `transition-opacity`,
        effect === `fade` && `opacity-0 duration-500 ease-in`,
        effect === `fade` && isLoaded && `opacity-100`,
        effect === `blur` && `grayscale blur-2xl`,
        effect === `blur` && isLoaded && `grayscale-0 blur-0`,

        className
      )}
      {...(other?.fill
        ? { src: imageProps?.src, loader: imageProps?.loader }
        : imageProps)}
      {...other}
      quality={100}
    />
  );
};

export default Image;
