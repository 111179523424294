"use client";

import { StarIcon } from "@heroicons/react/20/solid";
import { format, parseISO } from "date-fns";
import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import { OrderableItemTag, Product } from "@efarmz/efarmz-domain-typescript";
import Tag from "@efarmz/efarmz-react-commons/esm/components/atoms/Tag";
import Tooltip from "@efarmz/efarmz-react-commons/esm/components/atoms/Tooltip";
import useCurrency from "@efarmz/efarmz-react-commons/esm/hooks/useCurrency";

import Flag from "@/components/atoms/Flag";

import Image from "../../atoms/Image/Image";
import ProductActionButtons from "../ProductActionButtons";
import ProductCardTrack from "./ProductCardTrack";

export const search_attributes = [
  `id`,
  `name`,
  `brand.name`,
  `image`,
  `origin`,
  `discount`,
  `tags`,
  `deposit`,
  `slug`,
  `vatId`,
  `type`,
  `offDays`,
  `stock`,
  `isOnline`,
  `finalPrice`,
  `averageRate`,
  `categories`,
  `conditioning`,
  `expirationDate`,
  `isAvailableForSubscription`,
];

export type ProductCardProps = {
  product: Product;
  className?: string;
  style?: object;
  imgWrapperProps?: {
    className?: string;
    style?: object;
  };
  onBuyButtonClick?: () => void;
  footerProps?: Omit<React.InputHTMLAttributes<HTMLDivElement>, `size`>;
  informationWrapperProps?: React.InputHTMLAttributes<HTMLDivElement>;
};

const ProductCard = ({
  product,
  className,
  style,
  imgWrapperProps = {},
  onBuyButtonClick,
  footerProps = {},
  informationWrapperProps = {},
}: ProductCardProps) => {
  const tKey = `components/ProductCard`;
  const t = useTranslations();
  const currency = useCurrency();

  const { className: imgWrapperClassName, ...other } = imgWrapperProps;
  const { className: footerClassName, ...otherFooterProps } = footerProps;
  const {
    className: informationWrapperClassName,
    ...otherInformationWrapperProps
  } = informationWrapperProps;

  let expirationDate = product?.expirationDate
    ? typeof product?.expirationDate === `string`
      ? parseISO(product.expirationDate)
      : product.expirationDate
    : null;

  const Tags = product?.tags?.length
    ? product.tags
        .filter(
          (tag) => ![OrderableItemTag.BIO, OrderableItemTag.PROMO].includes(tag)
        )
        .map((tag) => {
          return (
            <Tooltip content={t(`dataset.Tags.${tag}`)} key={tag}>
              <Tag
                tag={OrderableItemTag[tag as keyof typeof OrderableItemTag]}
                label={t(`dataset.Tags.${tag}`)}
                size="xs"
                showIcon={false}
              />
            </Tooltip>
          );
        })
    : [];

  const showDiscount =
    product?.tags?.includes(OrderableItemTag.PROMO) ||
    product?.tags?.includes(OrderableItemTag.QUICKSALE) ||
    product?.tags?.includes(OrderableItemTag.FLASH_HENRY) ||
    product?.tags?.includes(OrderableItemTag.ZERO_WASTE);

  return (
    <ProductCardTrack
      product={product}
      className={twMerge(
        `relative group flex flex-col w-full overflow-hidden rounded-lg rounded-br-none rounded-bl-none  bg-white focus-within:ring-2 focus-within:ring-menthe-300 focus-within:ring-offset-2 focus-within:ring-offset-gray-100`,
        className
      )}
    >
      <div
        className={twMerge(
          `relative aspect-w-6 aspect-h-4`
          // imgWrapperClassName
        )}
        {...other}
      >
        {/*
         <div
          aria-hidden="true"
          className="bg-gradient-to-b from-transparent via-transparent  to-black opacity-100"
        />
        */}
        <Image
          src={`https://cdn.efarmz.be/art/${product.image}`}
          fill
          sizes="290px"
          alt={t(`${tKey}.image.alt`, {
            name: product?.name,
          })}
          className="pointer-events-none object-cover group-hover:opacity-75 absolute top-0 left-0 right-0"
        />

        <div className="absolute  z-10 top-0 bottom-0 w-full flex flex-col justify-between p-3">
          <div className="flex z-50 justify-between items-center">
            <div />
            <div>
              {product?.origin && (
                <Flag className="w-6 rounded-sm" code={product?.origin} />
              )}
            </div>
          </div>
          <div className="flex z-50 justify-between items-center">
            {product?.tags?.includes(OrderableItemTag.BIO) ? (
              <Tag
                tag={OrderableItemTag.BIO}
                label={t(
                  `dataset.Tags.${OrderableItemTag.BIO}`
                )?.toLocaleUpperCase()}
                size="xs"
                active
                showIcon={false}
              />
            ) : (
              <div />
            )}
            <div>
              {showDiscount && (
                <div>
                  {product?.discount ? (
                    <Tag
                      tag={OrderableItemTag.PROMO}
                      label={`-${product?.discount?.rate}%`}
                      size="xs"
                      active
                      showIcon={false}
                    />
                  ) : (
                    product?.tags?.includes(OrderableItemTag.PROMO) && (
                      <Tag
                        tag={OrderableItemTag.PROMO}
                        label={t(`dataset.Tags.${OrderableItemTag.PROMO}`)}
                        size="xs"
                        active
                        showIcon={false}
                      />
                    )
                  )}
                </div>
              )}
            </div>

            {/*product?.origin ? (
              <Flag code={product.origin} className="w-7 rounded-md h-6" />
            ) : (
              <div />
            )*/}
          </div>
        </div>
      </div>

      <div className="p-3 grow flex flex-col justify-between">
        <div className="grow">
          <div className="flex justify-between">
            <div>
              <h4 className="text-md font-semibold text-black">
                {product.name}
              </h4>
              <p className="text-sm text-menthe-500 font-medium">
                {product?.brand?.name || `-`}
              </p>
              <div className="py-3 flex items-center gap-2 flex-wrap">
                {Tags}
              </div>
            </div>
          </div>
        </div>

        {expirationDate && (
          <span className="text-menthe-800 text-xs py-2">
            {t(`${tKey}.expiration-date`, {
              date: format(expirationDate, `dd/MM/yyyy`),
            })}
          </span>
        )}

        <div
          className={twMerge(
            `flex justify-between mt-2 items-center`,
            informationWrapperClassName
          )}
          {...otherInformationWrapperProps}
        >
          <div className="flex items-center">
            <span
              className={twMerge(
                `bg-menthe-100 p-2 rounded-md text-sm text-vert-500 font-semibold`,
                showDiscount && product?.discount && `rounded-r-none`
              )}
            >
              {currency.format(product.finalPrice || 0)}
            </span>
            {showDiscount && product?.discount && (
              <span className="bg-rouge-100 p-2 rounded-r-md text-sm text-rouge-500 font-semibold line-through">
                {currency.format(product?.discount?.basePrice || 0)}
              </span>
            )}

            <span className="ml-2 text-sm text-gray-700">
              {product?.conditioning}
            </span>
          </div>
          <div className="flex space-x-2 items-center">
            {product?.averageRate && product?.averageRate > 0 && (
              <div className="bg-yellow-400 font-semibold flex items-center p-2 text-sm text-white rounded-md">
                <span className="mr-1">{product?.averageRate}</span>
                <StarIcon
                  className={twMerge(`h-4 w-4 flex-shrink-0 text-white`)}
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
        </div>
        <ProductActionButtons
          product={product}
          className={twMerge(footerClassName, `mt-4`)}
          {...footerProps}
        />
      </div>
    </ProductCardTrack>
  );
};

export default ProductCard;
