import { twMerge } from "tailwind-merge";

import { ProductCardSkeleton } from "@/components/molecules/ProductCard";
import { ProductCardSkeletonProps } from "@/components/molecules/ProductCard/ProductCardSkeleton";

type ProductListSliderSkeletonProps = {
  length?: number;
  productCardSkeletonProps?: ProductCardSkeletonProps;
  itemClassName?: string;
};

const ProductListSliderSkeleton = ({
  length = 5,
  productCardSkeletonProps = {},
  itemClassName,
}: ProductListSliderSkeletonProps) => {
  const Products = Array.from(Array(length).keys()).map((key, i) => {
    return (
      <div className={twMerge(`w-[262px] h-[467px]`, itemClassName)} key={key}>
        <ProductCardSkeleton {...productCardSkeletonProps} />
      </div>
    );
  });

  return (
    <div className="overflow-x-hidden">
      <div className="flex w-[2000px] gap-6">{Products}</div>
    </div>
  );
};

export default ProductListSliderSkeleton;
