import { useMemo } from "react";
import { InstantSearch } from "react-instantsearch";

import Client from "@searchkit/instantsearch-client";
import { useSearchParams } from "next/navigation";
import Searchkit from "searchkit";

type SearchProviderProps = {
  index: string;
  settings: any;
  children: React.ReactNode;
  searchState?: any;
  onSearchStateChange?: any;
  createURL?: any;
  routing?: boolean;
};

const SearchProvider = ({
  index,
  settings,
  children,
  routing = false,
  searchState,
  onSearchStateChange,
  createURL,
}: SearchProviderProps) => {
  const params = useSearchParams();

  const searchClient = useMemo(() => {
    const sk = new Searchkit({
      connection: {
        host: `/search`,
        apiKey: process.env.NEXT_PUBLIC_SEARCH_API_KEY,
      },
      search_settings: settings,
    });

    return Client(sk as any);
  }, []);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={index}
      routing={routing}
      key={routing ? params?.toString() : undefined}
      // createURL={createURL}
      //onSearchStateChange={onSearchStateChange}
      // searchState={searchState}
    >
      {children}
    </InstantSearch>
  );
};

export default SearchProvider;
