import { Category } from "@efarmz/efarmz-domain-typescript";

const urlFromCategory = (locale: string, categories: Category[]) => {
  const params: Record<string, string> = {};

  categories.forEach((category, i) => {
    params[
      `orderable-items-${locale}[hierarchicalMenu][categories_level_0][${i.toString()}]`
    ] = category.name || ``;
  });
  const searchParams = new URLSearchParams(params);
  return `/shop?${searchParams.toString()}`;
};
export default urlFromCategory;
