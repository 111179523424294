import { useMemo, useState } from "react";

import { addDays, format, getDay } from "date-fns";
import { useLocale, useTranslations } from "next-intl";
import { useRecoilState } from "recoil";
import { twMerge } from "tailwind-merge";

import { config } from "@efarmz/efarmz-domain-typescript";
import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import { search_attributes } from "@/components/molecules/ProductCard";

import ProductListSliderElastic from "@/components/organisms/ProductListSliderElastic";

import SanityProductCategorySliderSection from "../SanityProductCategorySliderSection";

import { ProductCardLegacyProps } from "@/components/ProductCardLegacy";
import { DeliveryDateLegacyAtom } from "@/state/atoms/DeliveryDateLegacyAtom";

type SanityProductTagSectionProps = {
  section: any;
  className?: string;
  legacy?: boolean;
};

const SanityProductTagSection = ({
  section,
  className,
  legacy = false,
}: SanityProductTagSectionProps) => {
  const [currentTag, setCurrentTag] = useState<any>(
    section?.tags ? section.tags[0] : null
  );

  const locale = useLocale();
  const t = useTranslations(`dataset`);

  const [deliveryDate] = useRecoilState(DeliveryDateLegacyAtom);
  const nextDefaultDelivery = addDays(new Date(), 2);

  const defaultDeliveryDate = format(
    getDay(nextDefaultDelivery) === 0
      ? addDays(nextDefaultDelivery, 1)
      : nextDefaultDelivery,
    `yyyyMMdd`
  );

  const handleSelectTag = (tag: any) => () => {
    setCurrentTag(tag);
  };

  const sortOption = useMemo(() => {
    let sort = `stats.sell-qty-${locale}`;

    if (section?.sort === `CREATED_AT`) {
      sort = `createdAt`;
    }
    if (section?.sort === `UPDATED_AT`) {
      sort = `updatedAt`;
    }

    return sort;
  }, [section?.sort, locale]);

  const Tags = section?.tags.map((item: any, i: number) => {
    return (
      <Button
        key={i.toString()}
        className={twMerge(
          `flex bg-white rounded-md overflow-hidden items-center p-0 hover:bg-menthe-100`,
          item?._key === currentTag?._key && `bg-menthe-300 hover:bg-menthe-300`
        )}
        onClick={handleSelectTag(item)}
        size="xl"
      >
        <div
          className={twMerge(
            `w-10 h-10  text-white flex items-center justify-center`
          )}
          style={{
            backgroundColor: item?.color?.hex,
          }}
        >
          {` `}
        </div>
        <div className="px-5 text-slate-700 font-medium grow">
          {item._type === `ProductTagCustomTab`
            ? item?.tag
            : t(`Tags.${item?.tag}`)}
        </div>
      </Button>
    );
  });

  const Slider = useMemo(() => {
    const hitProps: Omit<ProductCardLegacyProps, `product`> = currentTag?.color
      ?.hex
      ? {
          imgWrapperProps: {
            className: `border-b-4`,
            style: {
              borderColor: currentTag?.color?.hex,
            },
          },
        }
      : { className: undefined, style: undefined };
    if (currentTag?._type === `ProductTagCustomTab`) {
      return (
        <SanityProductCategorySliderSection
          section={{ ...currentTag, variant: `SLIDER` }}
          legacy={legacy}
          className={className}
          hitProps={hitProps}
        />
      );
    }

    return (
      <ProductListSliderElastic
        settings={{
          sorting: {
            default: {
              field: sortOption,
              order: `desc`,
            },
          },
          search_attributes: [`name`],
          result_attributes: search_attributes,
          facet_attributes: [
            {
              attribute: `brand`,
              field: `brand.name.keyword`,
              type: `string`,
            },
            {
              attribute: `categories_level_0`,
              field: `categories_level_0.keyword`,
              type: `string`,
            },
            {
              attribute: `categories_level_1`,
              field: `categories_level_1.keyword`,
              type: `string`,
            },
            {
              attribute: `categories_level_2`,
              field: `categories_level_2.keyword`,
              type: `string`,
            },
          ],
          filter_attributes: [
            { attribute: `type`, field: `type.keyword`, type: `string` },
            { attribute: `isOnline`, field: `isOnline`, type: `string` },
            {
              attribute: `stock`,
              field: `stock.${deliveryDate || defaultDeliveryDate}`,
              type: `numeric`,
            },
            {
              attribute: `categoryId`,
              field: `categories.id.keyword`,
              type: `string`,
            },
            {
              attribute: `categories`,
              field: `categories.name.keyword`,
              type: `string`,
            },
            {
              attribute: `tags`,
              field: `tags.keyword`,
              type: `string`,
            },
          ],
          /*
search_attributes: [`name`, `email`],
result_attributes: [`id`, `name`, `email`],
highlight_attributes: [`name`],
*/
        }}
        filters={`isOnline:true AND type:PRODUCT AND tags:${
          currentTag?.tag
        } AND (${config.menuRootCategoryIds
          .map((catId) => `categoryId:${catId}`)
          .join(` OR `)})`}
        legacy={legacy}
        hitProps={hitProps}
        numericFilters={[`stock>0`]}
      />
    );
  }, [
    currentTag,
    sortOption,
    deliveryDate,
    defaultDeliveryDate,
    legacy,
    className,
  ]);

  return (
    <>
      <div className="grid gap-2 sm:gap-0 grid-cols-2 sm:flex pb-6 sm:space-x-6">
        {Tags}
      </div>
      {Slider}
    </>
  );
};

export default SanityProductTagSection;
